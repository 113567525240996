import { Link } from "wouter";

const SOLUTIONS = [
  {
    name: "Inicio",
    href: "/",
  },
  {
    name: "Proyectos",
    href: "/proyectos",
  },
  {
    name: "Herramientas",
    href: "/tools",
  },
  {
    name: "Contacto",
    href: "/contacto",
  },
];

export default function Footer() {
  return (
    <div className="relative px-6 lg:px-8 mt-10">
      <div className="mx-auto border-t border-zinc-100 pt-10 pb-16">
        <div className="mx-auto max-w-4xl">
          {SOLUTIONS.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm font-medium px-2 py-2 transition hover:text-teal-500"
            >
              {item.name}{" "}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
