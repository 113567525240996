import SocialIcons from "../components/SocialIcons";
import hepegaIA from "../assets/hepegaIA.PNG";
import { Link } from "wouter";
import { useEffect } from "react";

export default function MainBody() {
  useEffect(() => {
    document.title = "@hepega.dev | Desarrollador WEB";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-4xl ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="w-1/2 sm:max-w-xs lg:max-w-none lg:w-auto lg:pl-12">
              <img
                className="transform rotate-3 rounded-xl"
                alt="Retrato por inteligencia artificial de hepega.dev"
                src={hepegaIA}
              ></img>
            </div>
            <div className="lg:order-first lg:row-span-2">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
                Desarrollador de Aplicaciones Web y Creador de Contenido
              </h1>
              <div className="mt-6 text-base leading-8 text-gray-600">
                <p className="mt-2">
                  Hola! 👋🏽 Mi nombre es Héctor Peñalva, aunque quizás algunos me
                  conozcáis en redes cómo <strong>@hepega.dev.</strong>{" "}
                </p>
                <p className="mt-2">
                  Me entusiasma afrontar nuevos retos cómo desarrollador web, y
                  poder llegar a personas que comparten mis intereses cómo
                  creador de contenido.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-8">
                  <Link to="/proyectos" className="">
                    <button class="bg-teal-500 hover:bg-teal-700 text-white font-bold text-base py-3 px-5 rounded">
                      MIS PROYECTOS
                    </button>
                  </Link>
                  <Link to="/tools" className="">
                    <button class="bg-teal-500 hover:bg-teal-700 text-white font-bold text-base py-3 px-5 rounded ">
                      MIS HERRAMIENTAS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:items-center lg:justify-center">
              {" "}
              <div>
                <SocialIcons />
              </div>
              <div className="border-t mt-3 pt-3">
                <a
                  href="mailto:contacto@hepega.dev"
                  className="text-sm text-gray-600 hover:text-teal-500 cursor-pointer"
                >
                  contacto@hepega.dev
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
