import { useEffect } from "react";

const PROJECTS = [
  {
    name: "Tienda Llumimims",
    description: "Tienda online Llumimims, desarrollado en WooCommerce",
    url: "https://www.llumimims.es",
    shortUrl: "llumimims.es",
  },
  {
    name: "Portfolio",
    description: "Mi propio portfolio, desarrollado en React + Tailwind",
    url: "https://www.hepega.dev",
    shortUrl: "hepega.dev",
  },
  {
    name: "Giphy Finder",
    description: "App para buscar GIFS, desarrollado en React + Bootstrap",
    url: "https://giphy-finder.vercel.app/",
    shortUrl: "giphy-finder.vercel.app",
  },
  {
    name: "Task List",
    description: "Lista de tareas, desarrollado en React + CSS",
    url: "https://hectorpenalva.github.io/task-list/",
    shortUrl: "hectorpenalva.github.io/task-list",
  },
];

export default function Projects() {
  useEffect(() => {
    document.title = "@hepega.dev | Proyectos";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-4xl ">
          <h2 className="text-4xl font-bold tracking-tight sm:text-6xl">
            Puedes encontrar mis mejores proyectos aquí.
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            He trabajado en muchos proyectos a lo largo de los últimos años,
            desde sencillos sitios web a complejos negocios en línea,
            aprovechando mis habilidades e imaginación ✨.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Estos son algunos de los trabajos de los que más orgulloso estoy:
          </p>

          <div className="mt-14">
            <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3">
              {PROJECTS.map((project) => {
                return (
                  <a href={project.url} target="_blank" rel="noreferrer">
                    <li className="border rounded p-4 hover:bg-gray-50">
                      {" "}
                      <h2 className="text-sm font-bold sm:text-base mb-2">
                        {project.name}
                      </h2>
                      <p className="text-sm text-gray-600">
                        {project.description}
                      </p>
                      <a
                        href={project.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm text-gray-600 hover:text-teal-500"
                      >
                        🔗 {project.shortUrl}
                      </a>
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>

          <p className="mt-6 text-base leading-8 text-gray-600">
            Puedes ver el resto en mi{" "}
            <a
              className="hover:text-teal-500 font-bold"
              href="https://github.com/hectorpenalva"
              target="_blank"
              rel="noreferrer"
            >
              Git Hub
            </a>{" "}
            ✌🏼
          </p>
        </div>
      </section>
    </main>
  );
}
