import "./App.css";
import { Route } from "wouter";
import Main from "./pages/Main";
import Tools from "./pages/Tools";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Margin from "./components/Margin";

export default function App() {
  return (
    <>
      <Margin />
      <Navbar />
      <Route path="/" component={Main} />
      <Route path="/proyectos" component={Projects} />
      <Route path="/tools" component={Tools} />
      <Route path="/contacto" component={Contact} />
      <Footer />
    </>
  );
}
