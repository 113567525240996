import { Fragment } from "react";
import { Link } from "wouter";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import SocialIcons from "./SocialIcons";
const SOLUTIONS = [
  {
    name: "Inicio",
    href: "/",
  },
  {
    name: "Proyectos",
    href: "/proyectos",
  },
  {
    name: "Herramientas",
    href: "/tools",
  },
  {
    name: "Contacto",
    href: "/contacto",
  },
];

export default function Navbar() {
  return (
    <Popover className="relative">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-end md:justify-center py-6 sm:pb-24 md:space-x-10 ">
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden px-2 space-x-1 md:flex border border-gray-100 rounded-full shadow-md"
          >
            {SOLUTIONS.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="text-sm font-medium px-2 py-2 transition hover:text-teal-500"
              >
                {item.name}{" "}
              </Link>
            ))}
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-50"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between border-b-2">
                <div className="pl-4 text-xs text-gray-900">
                  <SocialIcons />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-3">
                <nav className="grid gap-y-6">
                  {SOLUTIONS.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center rounded-md p-2 hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
