import { useEffect } from "react";
import SocialIcons from "../components/SocialIcons";

export default function Contact() {
  useEffect(() => {
    document.title = "@hepega.dev | Contacto";
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-4xl ">
          <h2 className="text-4xl font-bold tracking-tight sm:text-6xl">
            ¿Hablamos?
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            ¿Necesitas ayuda con algo en particular? No dudes en ponerte en
            contacto conmigo utilizando el formulario que aparece a
            continuación.
          </p>

          <div className="mt-14">
            <form
              class="w-full max-w-lg"
              action="https://formspree.io/f/mvoyzera"
              method="POST"
              id="contact"
              name="contact"
              accept-charset="utf-8"
            >
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Nombre
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    name="name"
                    placeholder="Hector"
                    required
                  />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-email"
                  >
                    Email
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    required
                  />
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-message"
                  >
                    Mensaje
                  </label>
                  <textarea
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    type="text"
                    name="message"
                    placeholder="¿En qué te puedo ayudar?"
                    required
                    minlength="20"
                  />
                  <button
                    type="submit"
                    class="bg-teal-500 hover:bg-teal-700 text-white font-bold text-base py-3 px-5 mt-3 rounded"
                  >
                    ENVIAR
                  </button>
                </div>
              </div>
            </form>
          </div>

          <p className="mt-6 text-base leading-8 text-gray-600">
            {" "}
            <SocialIcons />
          </p>
        </div>
      </div>
    </main>
  );
}
