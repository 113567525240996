export default function Margin() {
  return (
    <div>
      <div class="fixed inset-0 flex justify-center lg:px-8 bg-zinc-50">
        <div class="flex w-full max-w-7xl">
          <div class="w-full bg-white ring-1 ring-zinc-100"></div>
        </div>
      </div>
    </div>
  );
}
