import { useEffect } from "react";
import HectorBlob from "../assets/blob_hector-2.webp";
import directorio from "../assets/directorio.png";

const CATEGORIAS = [
  "Cursos",
  "Documentaciones",
  "Retos",
  "APIs",
  "Productividad",
  "Iconos",
  "Botones",
  "Loaders",
  "Degradados",
  "Sombras",
  "Paletas de Color",
  "Fotos Stock",
  "Edición Imagenes",
  "Ilustraciones",
  "Backgrounds",
  "Snippets",
  "Despliegue WEB",
  "Componentes",
];

export default function Tools() {
  useEffect(() => {
    document.title = "@hepega.dev | Herramientas";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-4xl ">
          <h2 className="mt-2 text-4xl font-bold tracking-tight sm:text-6xl">
            Herramientas para diseñar mejores productos
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6 ">
            <div>
              <p className="text-lg leading-8 text-gray-800">
                He recopilado más de{" "}
                <strong>
                  100 herramientas, cursos en línea, componentes, etc.
                </strong>{" "}
                que aconsejo a través de mis redes sociales en un solo
                directorio.
              </p>

              <div className="">
                <div className="flex mt-6">
                  <div className="flex items-center justify-center">
                    <img
                      alt=""
                      src={HectorBlob}
                      width="64"
                      height="64"
                      loading="lazy"
                    />
                  </div>
                  <div className="flex flex-col align-middle justify-center px-3  ">
                    <h3 className="font font-bold text-lg">@hepega.dev</h3>
                    <p className="">Desarrollador WEB y Creador de Contenido</p>
                  </div>
                </div>
                <div>
                  <a
                    href="https://hepegadev.gumroad.com/l/tools"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button class="bg-teal-500 hover:bg-teal-700 text-white font-bold text-base mt-8 py-3 px-5 rounded">
                      IR AL DIRECTORIO
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="sm:mx-12 hidden md:block">
              <img
                className="transform rotate-3 shadow "
                alt="Captura de pantalla del directorio de herramientas"
                src={directorio}
              />
            </div>
          </div>
          <div className="mt-12">
            <div className="sm:py-6 border-t">
              <div className="sm:w-5/6 ">
                <h2 className="text-lg leading-8 py-4 text-gray-800 font-bold tracking-tight sm:text-3xl">
                  ¿A quién va dirigido?{" "}
                </h2>
                <p className="text-lg tracking-tight pb-4 sm:text-lg">
                  El directorio <strong>es para todos</strong>, pero en especial
                  para Desarrolladores Web, Diseñadores Gráficos, Marketing
                  Digital o Estudiantes. Contiene desde cursos básicos para los
                  que simplemente quieren mejorar su formación hasta
                  herramientas avanzadas para profesionales.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="sm:py-6 border-t">
              <div className="sm:w-5/6">
                <h2 className="text-lg leading-8 py-4 text-gray-800 font-bold tracking-tight sm:text-3xl">
                  Actualización constante con acceso de por vida{" "}
                </h2>
                <p className="text-lg tracking-tight pb-4 sm:text-lg">
                  Mucho por muy poco. Cada nuevo recurso publicado en mis
                  cuentas de{" "}
                  <a
                    className="hover:text-teal-500 font-bold"
                    href="https://www.instagram.com/hepega.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>{" "}
                  o{" "}
                  <a
                    className="hover:text-teal-500 font-bold"
                    href="https://www.tiktok.com/@hepega.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TikTok
                  </a>{" "}
                  ¡y muchos más! inmediatamente disponibles.
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="sm:py-6 border-t">
              <div className="sm:w-5/6">
                <h2 className="text-lg leading-8 py-4 text-gray-800 font-bold tracking-tight sm:text-3xl">
                  Clasificado por categoría{" "}
                </h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-3 pt-2">
                {CATEGORIAS.map((categoria) => {
                  return (
                    <div className="md:p-2">
                      <div className="flex items-center justify-center border-gray-200  border p-4 md:rounded-lg">
                        <h2 className="text-gray-900 title-font font-medium">
                          {categoria}
                        </h2>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <a
              href="https://hepegadev.gumroad.com/l/tools"
              target="_blank"
              rel="noreferrer"
            >
              <button class="bg-teal-500 hover:bg-teal-700 text-white font-bold text-base mt-8 py-3 px-5 rounded">
                IR AL DIRECTORIO
              </button>
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}
